@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}

body{
  background-color: #e99851;
  overflow-x: hidden;
}